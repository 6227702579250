@font-face {
    font-family: 'OpenSans Bold';
    src: local('OpenSans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans BoldItalic';
    src: local('OpenSans BoldItalic'), local('OpenSans-BoldItalic'), url("../fonts/OpenSans-BoldItalic.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans ExtraBold';
    src: local('OpenSans ExtraBold'), local('OpenSans-ExtraBold'), url("../fonts/OpenSans-ExtraBold.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans ExtraBoldItalic';
    src: local('OpenSans ExtraBoldItalic'), local('OpenSans-ExtraBoldItalic'), url("../fonts/OpenSans-ExtraBoldItalic.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans Italic';
    font-style: normal;
    font-weight: 300;
    src: local('OpenSans Italic'), local('OpenSans-Italic'), url("../fonts/OpenSans-Italic.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans Light';
    src: local('OpenSans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans LightItalic';
    src: local('OpenSans LightItalic'), local('OpenSans-LightItalic'), url("../fonts/OpenSans-LightItalic.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans Regular';
    font-style: normal;
    font-weight: 300;
    src: local('OpenSans Regular'), local('OpenSans-Regular'), url("../fonts/OpenSans-Regular.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans SemiBold';
    src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'), url("../fonts/OpenSans-SemiBold.ttf") format('TrueType');
}

@font-face {
    font-family: 'OpenSans SemiBoldItalic';
    src: local('OpenSans SemiBoldItalic'), local('OpenSans-SemiBoldItalic'), url("../fonts/OpenSans-SemiBoldItalic.ttf") format('TrueType');
}

@mixin text-style-body-01 {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	line-height: 1.3;
}

@mixin text-style-body-02 {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	line-height: 1.3;
}

@mixin text-style-subtitle-01 {
	font-size: 32px;
    font-weight: 600;
	line-height: 1.3;
}

@mixin text-style-subtitle-02 {
	font-size: 24px;
	line-height: 1.3;
    font-weight: 600;
}

@mixin text-style-body-label {
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	line-height: 1.3;
}

@mixin effect-style-shadow-01 {
	box-shadow: 0.00px 8.00px 32.00px rgba(63,67,74,0.320);
}

html,
body {
    @include text-style-body-02;
    font-family: 'OpenSans Regular', 'Helvetica Neue', sans-serif;
    color: var(--aud-color-body);
}

h1 {
    @include text-style-subtitle-01;
}

h2 {
    @include text-style-subtitle-02;
}

p {
    @include text-style-body-01;
}

a,
a:hover,
a:visited {
    color: var(--aud-color-brand-regular);
    text-decoration: none;
    cursor: pointer;
}
