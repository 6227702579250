/* You can add utility global spacing styles to this file. Please keep 'aud' prefix */
$size: 5;

@while $size <= 250 {
    // PADDING
    .aud-p-#{$size} {
        padding: #{$size}px !important;
    };
    .aud-pt-#{$size} {
        padding-top: #{$size}px !important;
    };
    .aud-pb-#{$size} {
        padding-bottom: #{$size}px !important;
    };
    .aud-pl-#{$size} {
        padding-left: #{$size}px !important;
    };
    .aud-pr-#{$size} {
        padding-right: #{$size}px !important;
    };
    .aud-px-#{$size} {
        padding-left: #{$size}px !important;
        padding-right: #{$size}px !important;
    };
    .aud-py-#{$size} {
        padding-top: #{$size}px !important;
        padding-bottom: #{$size}px !important;
    };

    // MARGIN
    .aud-m-#{$size} {
        margin: #{$size}px !important;
    };
    .aud-mt-#{$size} {
        margin-top: #{$size}px !important;
    };
    .aud-mb-#{$size} {
        margin-bottom: #{$size}px !important;
    };
    .aud-ml-#{$size} {
        margin-left: #{$size}px !important;
    };
    .aud-mr-#{$size} {
        margin-right: #{$size}px !important;
    };
    .aud-mx-#{$size} {
        margin-left: #{$size}px !important;
        margin-right: #{$size}px !important;
    };
    .aud-my-#{$size} {
        margin-top: #{$size}px !important;
        margin-bottom: #{$size}px !important;
    };

    $size: $size + 5;
}
