.form-group {
    margin-bottom: 25px;

    label {
        @include text-style-body-02;
        color: var(--aud-color-body-light);
    }

    .form-control-wrapper {
        border: 1px solid;
        border-color: var(--aud-color-lightest-regular);
    }

}

.form-control {
    font-size: 14px;
    padding-right: calc(1.5em + .75rem);
    height: 38px;
    width: 100%;
}

input[type='checkbox'].custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--aud-color-brand-regular);
    border-color: var(--aud-color-brand-regular);
    width: 16px;
    height: 16px;
}

.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.ui-chkbox .ui-chkbox-icon {
    width: 16px;
    height: 16px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-size: 10px;
}

.ui-chkbox-box.ui-widget.ui-state-default {
    .ui-chkbox-icon {
        background-color: #fff;
        border: #adb5bd solid 1px;
        border-radius: .25rem;
    }

    &.ui-state-active {
        .ui-chkbox-icon {
            background-color: var(--aud-color-water-regular);
            border-color: var(--aud-color-water-regular);
            color: #fff;
        }
    }
}

.ng-select .ng-select-container {
    border-radius: 14px;
    border-color: var(--aud-color-lightest-regular) !important;
    height: 38px !important;
    padding: 0 5px;
}

.ng-dropdown-panel {
    border-color: var(--aud-color-lightest-regular) !important;
}
