@font-face {
    font-family: 'audicon';
    src:  url('../fonts/audicon.eot?byyrfo');
    src:  url('../fonts/audicon.eot?byyrfo#iefix') format('embedded-opentype'),
    url('../fonts/audicon.ttf?byyrfo') format('truetype'),
    url('../fonts/audicon.woff?byyrfo') format('woff'),
    url('../fonts/audicon.svg?byyrfo#audicon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.audicon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'audicon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.aud-i-alert:before {
    content: "\e900";
}
.aud-i-archive:before {
    content: "\e901";
}
.aud-i-arrow-down-full:before {
    content: "\e902";
}
.aud-i-arrow-down:before {
    content: "\e903";
}
.aud-i-arrow-left:before {
    content: "\e904";
}
.aud-i-arrow-right-long:before {
    content: "\e905";
}
.aud-i-arrow-right:before {
    content: "\e906";
}
.aud-i-attachment:before {
    content: "\e907";
}
.aud-i-bell:before {
    content: "\e908";
}
.aud-i-calendar:before {
    content: "\e909";
}
.aud-i-camera:before {
    content: "\e90a";
}
.aud-i-cart:before {
    content: "\e90b";
}
.aud-i-chart:before {
    content: "\e90c";
}
.aud-i-chat:before {
    content: "\e90d";
}
.aud-i-check-square:before {
    content: "\e90e";
}
.aud-i-check:before {
    content: "\e90f";
}
.aud-i-coffee:before {
    content: "\e910";
}
.aud-i-contacts:before {
    content: "\e911";
}
.aud-i-copy:before {
    content: "\e912";
}
.aud-i-cross:before {
    content: "\e913";
}
.aud-i-csv:before {
    content: "\e914";
}
.aud-i-delete:before {
    content: "\e915";
}
.aud-i-discount:before {
    content: "\e916";
}
.aud-i-dollar-full:before {
    content: "\e917";
}
.aud-i-dollar:before {
    content: "\e918";
}
.aud-i-download:before {
    content: "\e919";
}
.aud-i-drag:before {
    content: "\e91a";
}
.aud-i-edit:before {
    content: "\e91b";
}
.aud-i-excel:before {
    content: "\e91c";
}
.aud-i-facebook:before {
    content: "\e91d";
}
.aud-i-filter:before {
    content: "\e91e";
}
.aud-i-flag:before {
    content: "\e91f";
}
.aud-i-folder-full:before {
    content: "\e920";
}
.aud-i-folder-move:before {
    content: "\e921";
}
.aud-i-folder:before {
    content: "\e922";
}
.aud-i-grid:before {
    content: "\e923";
}
.aud-i-hide:before {
    content: "\e924";
}
.aud-i-image:before {
    content: "\e925";
}
.aud-i-important-full:before {
    content: "\e926";
}
.aud-i-important:before {
    content: "\e927";
}
.aud-i-inbox:before {
    content: "\e928";
}
.aud-i-instagram:before {
    content: "\e929";
}
.aud-i-like-full:before {
    content: "\e92a";
}
.aud-i-like:before {
    content: "\e92b";
}
.aud-i-link:before {
    content: "\e92c";
}
.aud-i-list-bullet:before {
    content: "\e92d";
}
.aud-i-list:before {
    content: "\e92e";
}
.aud-i-lock:before {
    content: "\e92f";
}
.aud-i-logout:before {
    content: "\e930";
}
.aud-i-mail:before {
    content: "\e931";
}
.aud-i-map:before {
    content: "\e932";
}
.aud-i-menu:before {
    content: "\e933";
}
.aud-i-minus:before {
    content: "\e934";
}
.aud-i-more:before {
    content: "\e935";
}
.aud-i-move:before {
    content: "\e936";
}
.aud-i-music:before {
    content: "\e937";
}
.aud-i-notebook:before {
    content: "\e938";
}
.aud-i-pantone:before {
    content: "\e939";
}
.aud-i-pdf:before {
    content: "\e93a";
}
.aud-i-phone:before {
    content: "\e93b";
}
.aud-i-pin:before {
    content: "\e93c";
}
.aud-i-plus:before {
    content: "\e93d";
}
.aud-i-printer:before {
    content: "\e93e";
}
.aud-i-project:before {
    content: "\e93f";
}
.aud-i-pushpin:before {
    content: "\e940";
}
.aud-i-read-mail:before {
    content: "\e941";
}
.aud-i-reply-all:before {
    content: "\e942";
}
.aud-i-reply:before {
    content: "\e943";
}
.aud-i-search:before {
    content: "\e944";
}
.aud-i-send-full:before {
    content: "\e945";
}
.aud-i-send:before {
    content: "\e946";
}
.aud-i-settings:before {
    content: "\e947";
}
.aud-i-share:before {
    content: "\e948";
}
.aud-i-shopping:before {
    content: "\e949";
}
.aud-i-show:before {
    content: "\e94a";
}
.aud-i-slide-filter:before {
    content: "\e94b";
}
.aud-i-smartphone:before {
    content: "\e94c";
}
.aud-i-smile:before {
    content: "\e94d";
}
.aud-i-sort:before {
    content: "\e94e";
}
.aud-i-sport:before {
    content: "\e94f";
}
.aud-i-square:before {
    content: "\e950";
}
.aud-i-star-full:before {
    content: "\e951";
}
.aud-i-star:before {
    content: "\e952";
}
.aud-i-sun:before {
    content: "\e953";
}
.aud-i-tag:before {
    content: "\e954";
}
.aud-i-tasks:before {
    content: "\e955";
}
.aud-i-text:before {
    content: "\e956";
}
.aud-i-time:before {
    content: "\e957";
}
.aud-i-travel:before {
    content: "\e958";
}
.aud-i-twitter:before {
    content: "\e959";
}
.aud-i-update:before {
    content: "\e95a";
}
.aud-i-upload-cloud:before {
    content: "\e95b";
}
.aud-i-upload:before {
    content: "\e95c";
}
.aud-i-user-add:before {
    content: "\e95d";
}
.aud-i-user-full:before {
    content: "\e95e";
}
.aud-i-user:before {
    content: "\e95f";
}
.aud-i-vector:before {
    content: "\e960";
}
.aud-i-zap:before {
    content: "\e961";
}
