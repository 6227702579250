// Brand colors
:root {
    --aud-color-brand-dark: #00A345;
    --aud-color-brand-regular: #00BF51;
    --aud-color-brand-light: #00E561;
}

// Resources Colors
:root {
    --aud-color-power-dark: #E58200;
    --aud-color-power-regular: #FCA32D;
    --aud-color-power-light: #FFC880;
    --aud-color-gas-dark: #CC3333;
    --aud-color-gas-regular: #FF4040;
    --aud-color-gas-light: #FF8080;
    --aud-color-water-dark: #1E85A6;
    --aud-color-water-regular: #28B2DE;
    --aud-color-water-regular-opacity: rgba(40, 178, 222, .1);
    --aud-color-water-light: #6FC3DE;
    --aud-color-waste-dark: #2C995A;
    --aud-color-waste-regular: #41E185;
    --aud-color-waste-light: #C7FFDF;
}

// Aux Colors
:root {
    --aud-color-danger-dark: #E84646;
    --aud-color-danger-regular: #FF5959;
    --aud-color-danger-light: #FF8C8C;
    --aud-color-danger-lighter: #ffd6d6;
    --aud-color-warning-dark: #FCC419;
    --aud-color-warning-regular: #FFE066;
    --aud-color-warning-light: #FFF3BF;
    --aud-color-warning-lighter: #fffae5;
    --aud-color-success-dark: #20C997;
    --aud-color-success-regular: #38D9A9;
    --aud-color-success-light: #63E6BE;
    --aud-color-success-lighter: #dcf9f0;
    --aud-color-info-dark: #228BE6;
    --aud-color-info-regular: #4DABF7;
    --aud-color-info-light: #A5D8FF;
    --aud-color-info-lighter: #e5f4ff;
}

// Gray Colors
:root {
    --aud-color-darkest-dark: #33303E;
    --aud-color-darkest-regular: #4E4B59;
    --aud-color-darkest-light: #5F5C6B;
    --aud-color-lightest-dark: #CED4DA;
    --aud-color-lightest-regular: #DEE2E6;
    --aud-color-lightest-light: #E9ECEF;
}

// Text Colors
:root {
    --aud-color-body: #3F434A;
    --aud-color-body-light: #8A9099;
}

// Gradients
:root {
    --aud-green-to-blue-gradient: linear-gradient(296.96deg, #006BCD -3.47%, #00BF51 92.98%);
}
