/* You can add global styles to this file, and also import other style files */
@import "~@swimlane/ngx-datatable/index.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "fonts";
@import "icons";
@import "colors";
@import "utils";
@import "spacing";
@import "forms";

* {
    box-sizing: border-box;
}

body {
    position: relative;
    background: #F7F8FB;
}

.aud-gradient-green-to-blue-bg {
    background: var(--aud-green-to-blue-gradient);
}

.aud-content {
    padding-top: 105px;
    padding-bottom: 50px;
}

.aud-underline {
    background: var(--aud-green-to-blue-gradient);
    width: 210px;
    height: 4px;
}

.ui-toast-message {
    background-color: #fff;
    border-radius: 10px;
    padding: 0 10px;

    &.ui-toast-message-error {
        background-color: var(--aud-color-danger-lighter);

        .ui-toast-icon {
            color: var(--aud-color-danger-dark);
        }
    }

    &.ui-toast-message-warn {
        background-color: var(--aud-color-warning-lighter);

        .ui-toast-icon {
            color: var(--aud-color-warning-dark);
        }
    }

    &.ui-toast-message-success {
        background-color: var(--aud-color-success-lighter);

        .ui-toast-icon {
            color: var(--aud-color-success-dark);
        }
    }

    &.ui-toast-message-info {
        background-color: var(--aud-color-info-lighter);

        .ui-toast-icon {
            color: var(--aud-color-info-dark);
        }
    }

    .ui-toast-message-content {
        .ui-toast-close-icon {
            color: var(--aud-color-body);
            padding: 5px
        }
    }
}

.ui-overlaypanel {
    position: absolute;
    margin: 10px 0 0;
    background-color: #ffffff;
    padding: 0;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    box-shadow: 0px 20px 50px rgba(44, 63, 88, 0.15);

    .ui-overlaypanel-content {
        padding: 15px 10px 20px 10px;
    }
}

.ui-table {
    background: #fff;
    border-radius: 15px;
    padding: 0;

    .ui-table-caption {
        padding: 25px 25px 0 25px;
    }
}

.ngx-datatable {
    .datatable-header {
        padding: 15px 20px;
        background-color: var(--aud-color-lightest-light);
        border-radius: 5px;

        .datatable-header-cell {
            @include text-style-body-02;
            font-weight: bold;
            color: var(--aud-color-body);

            .datatable-header-cell-wrapper {
                padding-right: 10px;
                width: 100%;
            }

            .sort-btn {
                &::before {
                    font-family: 'audicon';
                    display: none;
                    content: "\e902";
                }

                &.sort-asc::before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .datatable-body {
        .datatable-body-row {
            padding: 15px 15px;
            border: 1px solid #E8E9EB;
            border-radius: 5px;
            margin-top: 10px;
            transition: background-color .1s ease-in-out;
            
            &.active {
                background-color: #F8F8F8;
            }

            .datatable-body-cell {
                display: flex;
                align-items: center;
            }
        }

        .empty-row {
            padding: 25px;
        }
    }

    &.notificationTable {
        .datatable-body-cell {
            &.right-text {
                justify-content: center;
            }

            .datatable-body-cell-label {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-right: 15px;
                
                .aud-truncate-text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 95%;
                }
            }
        }
    }

    .datatable-footer {
        padding: 25px 4px;

        .aud-table-footer-text {
            color: var(--aud-color-body-light);
            font-size: 12px;
        }
    }

    .datatable-checkbox {
        input[type='checkbox'].custom-control-input:indeterminate ~ .custom-control-label::before,
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: var(--aud-color-water-regular);
            border-color: var(--aud-color-water-regular);
        }
    }

    .datatable-pager {
        .pager {
            li {
                border-radius: 10px;
                margin: 0 2.5px !important;

                a {
                    color: var(--aud-color-body);
                    padding: 8px 13px;
                }

                a[role="button"] {
                    border-radius: 10px;
                    background: var(--aud-color-water-regular-opacity);
                    color: var(--aud-color-water-regular);
                }

                &.active {
                    background: var(--aud-color-water-regular);

                    a {
                        color: #fff;
                    }
                }

                &.disabled {
                    a[role="button"] {
                        background: #F8F8F8;
                        color: var(--aud-color-body-light);
                    }
                }
            }
        }
    }
}

.naked-btn {
    color: var(--aud-color-body);
    border: none;
    background-color: transparent;
}

.unread {
    
    font-weight: bolder;
}

.aud-popover-menu {
    min-width: 190px;

    .aud-popover-title {
        @include text-style-body-01;
        padding: 10px;
        margin: 0 5px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border-radius: 15px;
            padding: 0;
            margin: 0 5px;

            &:hover {
                background: #F8F8F8;
            }

            a {
                display: block;
                padding: 10px;
                color: var(--aud-color-body);
            }
        }
    }
}

.widgets-card {
    background: #fff;
    padding: 15px 20px;
    border-radius: 25px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.aud-card {
    background: #fff;
    padding: 40px 25px;
    border-radius: 15px;
}

.aud-btn {
    @include text-style-body-02;
    border-radius: 15px;
    border: 0;
    padding: 7px;

    &.aud-btn-full-width {
        width: 100%;
    }

    &.aud-btn-primary {
        background: var(--aud-color-brand-regular);
        color: #FFFFFF;
    }

    &.btn-secondary {
        background: #fff;
        color: var(--aud-color-body);
        padding: 10px 15px;
        border: 1px solid #ccc;
    }

    &[disabled] {
        opacity: 0.6;
    }

    &.stripped {
        padding: 0;
        background: transparent;

        &:active,
        &:focus {
            border: none;
            outline: none;
        }
    }

    &.btn-add {
        @include text-style-body-01;

        i {
            font-size: 20px;
            background-color: var(--aud-color-water-regular);
            padding: 7px 9px;
            border-radius: 8px;
            color: var(--aud-color-lightest-light);
        }
    }

    &.icon-btn {
        white-space: nowrap;
        display: flex;
        align-items: center;

        i {
            font-size: 18px;
        }
    }
}

